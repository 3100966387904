import React from 'react'

import styles from './style.module.scss'

import {
    TiLocationOutline,
    TiChevronRightOutline,
    TiStarOutline,
    TiPinOutline
} from 'react-icons/ti'

import { FaRegGem } from 'react-icons/fa'

import { FiDollarSign } from 'react-icons/fi'

import { Link } from 'gatsby'

import cn from 'classnames'

const SERVICES = [
    {
        icon: <TiChevronRightOutline />,
        title: 'properties & enquiries',
        to: '/properties-and-enquiries'
    },
    {
        icon: <TiLocationOutline />,
        title: 'locations',
        to: '/dubai-area-map'
    },
    {
        icon: <TiStarOutline />,
        title: 'commercial properties',
        to: '/commercial-properties'
    },
    {
        icon: <FaRegGem />,
        title: 'luxury properties',
        to: '/luxury-properties'
    },
    {
        icon: <FiDollarSign />,
        title: 'mortgage & insurance',
        to: '/mortgage-calculator'
    },
    {
        icon: <TiPinOutline />,
        title: 'real estate careers',
        to: '/jobs'
    }
]

const SecondaryServices = () => {
    return (
        <div className={styles.secondaryServices}>
            <div className={cn('wrapper', styles.wrapper)}>
                <div className={styles.services}>
                    {SERVICES.map((service, i) => (
                        <div key={i} className={styles.service}>
                            <Link
                                key={service.title}
                                to={service.to}
                                title={service.title}
                            >
                                {service.icon}
                                {service.title}
                            </Link>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}

export default SecondaryServices
