import React from 'react'

import Img from 'gatsby-image'
import styles from './style.module.scss'
import cn from 'classnames'
/*
 * This component is built using `gatsby-image` to automatically serve optimized
 * images with lazy loading and reduced file sizes. The image is loaded using a
 * `StaticQuery`, which allows us to load the image from directly within this
 * component, rather than having to pass the image data down from pages.
 *
 * For more information, see the docs:
 * - `gatsby-image`: https://gatsby.app/gatsby-image
 * - `StaticQuery`: https://gatsby.app/staticquery
 */

const ImageMobile = props => (
    <Img className={cn(styles.bannerImage, styles.mobile)} {...props} />
)
export default ImageMobile
