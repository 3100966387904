import React from 'react'

import { graphql } from 'gatsby'

import SEO from '../components/seo'

import Layout from '../components/layout'

import BannerImage from '../components/banner-image'

import SecondaryServices from '../components/secondary-services'

import InsightsSection from '../components/insights-section'

import BlogList from '../components/blog-list'

import Areas from '../components/areas'

import Developers from '../components/developers'

import SeoSearch from '../components/seo-search'

import OtherAreas from '../components/other-areas'

import GoogleAd from '../components/google-ad'

export default ({ data }) => {
    let home = data.allWordpressPage.edges[0].node
    return (
        <Layout noSecondaryHeader>
            <SEO
                title={home.yoast_meta.yoast_wpseo_title}
                description={home.yoast_meta.yoast_wpseo_metadesc}
            />
            <BannerImage />
            <SecondaryServices />
            <InsightsSection />
            <Developers limit={6} homepage />
            <GoogleAd
                client="ca-pub-5451792925218475"
                slot="7777309638"
                format="auto"
                responsive="true"
            />
            <Areas />
            <BlogList />
            <OtherAreas />
            <SeoSearch data={data} />
        </Layout>
    )
}

export const query = graphql`
    {
        allWordpressPage(filter: { slug: { eq: "home" } }) {
            edges {
                node {
                    yoast_meta {
                        yoast_wpseo_title
                        yoast_wpseo_metadesc
                    }
                }
            }
        }
        allWordpressWpSeoSearch {
            edges {
                node {
                    title
                    slug
                    wordpress_id
                    areas
                    seo_search_category
                }
            }
        }

        allWordpressWpAreas {
            edges {
                node {
                    name
                    slug
                    wordpress_id
                }
            }
        }

        allWordpressWpSeoSearchCategory {
            edges {
                node {
                    name
                    slug
                    wordpress_id
                }
            }
        }
    }
`
