import React from 'react'

import { useStaticQuery, graphql, Link } from 'gatsby'

import {
    TiGlobeOutline,
    TiGroupOutline,
    TiTags,
    TiInfoLargeOutline,
    TiSpannerOutline,
} from 'react-icons/ti'

import { FiChevronRight } from 'react-icons/fi'

import styles from './style.module.scss'

import cn from 'classnames'

import { SearchBox } from '../properties-enquiries'

import ProtectedSignedInContent from '../protected-signed-in-content'

import { ACCOUNT_TYPE_OWNER } from '../../constants'

export default ({ innerPage, noNegativeMargin, hideLinks = false }) => {
    const data = useStaticQuery(graphql`
        {
            allWordpressWpStatus {
                nodes {
                    wordpress_id
                    slug
                    name
                }
            }
            allWordpressWpType {
                nodes {
                    name
                    wordpress_id
                }
            }
            allWordpressWpLocation {
                nodes {
                    name
                    wordpress_id
                }
            }
        }
    `)
    return (
        <div
            className={cn(
                styles.searchBox,
                innerPage && styles.innerPage,
                noNegativeMargin && styles.noNegativeMargin
            )}
        >
            <div className={cn('wrapper', styles.wrapper)}>
                {!hideLinks && (
                    <ul className={styles.tabs}>
                        <li>
                            <Link to="/areas" title="Areas">
                                <TiGroupOutline />
                                <span>areas</span>
                            </Link>
                        </li>
                        <li>
                            <Link to="/developers" title="Developers">
                                <TiGlobeOutline />
                                <span>developers</span>
                            </Link>
                        </li>
                        <li>
                            <ProtectedSignedInContent
                                title="Post your enquiry"
                                message="You need to be signed in to post your enquiry"
                                whenNotLoggedInContainerClassName={
                                    styles.fakeLink
                                }
                                to="/post-enquiry"
                                whenNotLoggedIn={
                                    <>
                                        <TiInfoLargeOutline />
                                        <span>post enquiry</span>
                                        <div className={styles.freeBadge}>
                                            Free
                                        </div>
                                    </>
                                }
                                accountType={ACCOUNT_TYPE_OWNER}
                                whenLoggedIn={
                                    <Link
                                        to="/post-enquiry"
                                        title="Post enquiry"
                                    >
                                        <TiInfoLargeOutline />
                                        <span>post enquiry</span>
                                        <div className={styles.freeBadge}>
                                            Free
                                        </div>
                                    </Link>
                                }
                            />
                        </li>
                        <li>
                            <ProtectedSignedInContent
                                title="List for free"
                                message="You need to be signed in as an owner to list your property for free"
                                whenNotLoggedInContainerClassName={
                                    styles.fakeLink
                                }
                                to="/owner-property"
                                whenNotLoggedIn={
                                    <>
                                        <TiTags />
                                        <span>list property</span>
                                        <div className={styles.freeBadge}>
                                            Free
                                        </div>
                                    </>
                                }
                                accountType={ACCOUNT_TYPE_OWNER}
                                whenLoggedIn={
                                    <Link
                                        to="/owner-property"
                                        title="List property"
                                    >
                                        <TiTags />
                                        <span>list property</span>
                                        <div className={styles.freeBadge}>
                                            Free
                                        </div>
                                    </Link>
                                }
                            />
                        </li>
                        <li>
                            <Link
                                to="/property-services"
                                title="Property services"
                            >
                                <TiSpannerOutline />
                                property services
                            </Link>
                        </li>
                    </ul>
                )}
                <div className={styles.content}>
                    {!hideLinks && (
                        <div className={styles.links}>
                            <Link
                                to="/high-yield-properties"
                                title="Check out high yield properties"
                            >
                                Check out high yield properties
                            </Link>
                            <span>
                                <Link
                                    to="/upcoming-launches"
                                    title="Upcoming launches"
                                >
                                    Check out new launches
                                </Link>
                                <FiChevronRight
                                    className={styles.chevronRight}
                                />
                            </span>
                        </div>
                    )}
                    <SearchBox data={data} />
                </div>
            </div>
        </div>
    )
}
