import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import styles from './style.module.scss'
import ImageDesktop from './image-desktop'
import ImageMobile from './image-mobile'
import PropertiesSearchBox from '../search-box/properties-search-box'
import Button from '../button'

/*
 * This component is built using `gatsby-image` to automatically serve optimized
 * images with lazy loading and reduced file sizes. The image is loaded using a
 * `StaticQuery`, which allows us to load the image from directly within this
 * component, rather than having to pass the image data down from pages.
 *
 * For more information, see the docs:
 * - `gatsby-image`: https://gatsby.app/gatsby-image
 * - `StaticQuery`: https://gatsby.app/staticquery
 */

const BannerImage = () => (
    <StaticQuery
        query={graphql`
            {
                allWordpressPage(filter: { slug: { eq: "home" } }) {
                    edges {
                        node {
                            featured_media {
                                localFile {
                                    childImageSharp {
                                        fluid(maxWidth: 3000) {
                                            ...GatsbyImageSharpFluid_withWebp
                                        }
                                    }
                                }
                            }
                            acf {
                                banner_button_text
                                banner_button_link
                                banner_text_title
                                banner_text_top_subtitle
                                mobile_image {
                                    localFile {
                                        childImageSharp {
                                            fluid(maxWidth: 3000) {
                                                ...GatsbyImageSharpFluid_withWebp
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        `}
        render={data => {
            const settings = data.allWordpressPage.edges[0].node

            return (
                <div className={styles.banner}>
                    {settings.featured_media.localFile && (
                        <ImageDesktop
                            fluid={
                                settings.featured_media.localFile
                                    .childImageSharp.fluid
                            }
                        />
                    )}
                    {settings.acf.mobile_image.localFile && (
                        <ImageMobile
                            fluid={
                                settings.acf.mobile_image.localFile
                                    .childImageSharp.fluid
                            }
                        />
                    )}

                    <div className={styles.content}>
                        <div className="wrapper">
                            <div className="row">
                                <div className="col-xs-12">
                                    <h1 className={styles.title}>
                                        <span className={styles.heading}>
                                            {
                                                settings.acf
                                                    .banner_text_top_subtitle
                                            }
                                        </span>
                                        <div className={styles.subHeading}>
                                            {settings.acf.banner_text_title}
                                        </div>
                                    </h1>
                                    <Button
                                        secondary
                                        className={styles.readMore}
                                        link={settings.acf.banner_button_link}
                                        target="_blank"
                                        title={settings.acf.banner_button_text}
                                    >
                                        {settings.acf.banner_button_text}
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <PropertiesSearchBox />
                </div>
            )
        }}
    />
)
export default BannerImage
