import React from 'react'

import { Link } from 'gatsby'

import styles from './style.module.scss'

import cn from 'classnames'

import { TiArrowRight } from 'react-icons/ti'

const INSIGHTS = [
    {
        title: 'market research',
        text:
            'Get thorough market research reports from industry experts on the Property Market. Industry market Research Reports, Statistics, Data, Trends, Forecasts and related information.',
        link: '/market-research'
    },
    {
        title: 'real estate channel',
        text:
            'Property talk from the Founder and CEO of Arms &McGregor International Group® including Arms &McGregor Leisure, Vacations, Investments and Realty ®.',
        link: '/real-estate-channel'
    },
    {
        title: 'Property Mag',
        text:
            'Monthly magazine which empowers readers with latest real estate market trends, news, latest launches in Dubai, featured and popular reads, and much more.',
        link: '/property-mag'
    }
]

const InsightsSection = () => {
    return (
        <div className={styles.insightsSection}>
            <div className="wrapper">
                <div className="row">
                    {INSIGHTS.map((r, i) => (
                        <div
                            key={i}
                            className={cn('col-xs-12 col-sm-6 col-md-4', {
                                [styles.marginTop]: i === 2
                            })}
                        >
                            <div className={cn(styles.research)} key={r.title}>
                                <h3 className={styles.title}>{r.title}</h3>
                                <p>{r.text}</p>
                                <Link
                                    to={r.link}
                                    className={styles.link}
                                    title={r.title}
                                >
                                    Learn more <TiArrowRight size={25} />
                                </Link>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}

export default InsightsSection
