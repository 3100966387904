import React from 'react'

import { Link, StaticQuery, graphql } from 'gatsby'

import styles from './style.module.scss'

function chunk(arr, chunkSize) {
    var R = []
    for (var i = 0, len = arr.length; i < len; i += chunkSize)
        R.push(arr.slice(i, i + chunkSize))
    return R
}

export default () => (
    <div className={styles.areas}>
        <div className="wrapper">
            <div className={styles.otherAreas}>
                <h3 className={styles.otherAreasTitle}>Other areas in Dubai</h3>
                <div className="row">
                    <StaticQuery
                        query={graphql`
                            {
                                allWordpressWpAreas(skip: 8) {
                                    edges {
                                        node {
                                            name
                                            slug
                                        }
                                    }
                                }
                            }
                        `}
                        render={data => {
                            return chunk(
                                data.allWordpressWpAreas.edges.slice(0, 8 * 4),
                                8
                            ).map((chunk, i) => (
                                <div key={i} className="col-xs-6 col-sm-3">
                                    <ul className={styles.otherAreaList}>
                                        {chunk.map((edge, i) => (
                                            <li key={i}>
                                                <Link
                                                    to={`/area/${edge.node.slug}`}
                                                    title={edge.node.name}
                                                >
                                                    {edge.node.name}
                                                </Link>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            ))
                        }}
                    />
                </div>
            </div>
        </div>
    </div>
)
