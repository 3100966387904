import React from 'react'

import { TiArrowRight } from 'react-icons/ti'

import { Link, graphql, StaticQuery } from 'gatsby'

import moment from 'moment'

import styles from './style.module.scss'

export default () => (
    <StaticQuery
        query={graphql`
            {
                allWordpressPost(
                    limit: 8
                    sort: { fields: date, order: DESC }
                ) {
                    edges {
                        node {
                            slug
                            title
                            excerpt
                            date
                            categories {
                                name
                            }
                            tags {
                                name
                            }
                        }
                    }
                }
            }
        `}
        render={data => {
            return (
                <div className={styles.blogList}>
                    <Link
                        to="/blog"
                        className={styles.blogLink}
                        title="Real estate blog"
                    >
                        <div className={styles.beforeTitle}>Our Blog</div>
                        <h2 className={styles.title}>Dubai real estate blog</h2>
                        <div className={styles.afterTitle}>
                            read what matters with our execlusive carefully
                            crafted And Selected blogs
                        </div>
                    </Link>
                    <ul className={styles.blogs}>
                        {data.allWordpressPost.edges.map((edge, i) => (
                            <li key={i}>
                                <span className={styles.category}>
                                    {edge.node.categories
                                        .map(c => c.name)
                                        .join(', ')}
                                </span>
                                <h3
                                    dangerouslySetInnerHTML={{
                                        __html: edge.node.title
                                    }}
                                />
                                <div className={styles.bottomRow}>
                                    <span className={styles.date}>
                                        {moment(edge.node.date).format(
                                            'MMMM Do Y'
                                        )}
                                    </span>
                                    <span className={styles.tags}>
                                        {edge.node.tags &&
                                            edge.node.tags
                                                .map(c => c.name)
                                                .join(', ')}
                                    </span>
                                </div>
                                <Link
                                    to={`/blog/${edge.node.slug}`}
                                    className={styles.link}
                                    title="Read more"
                                >
                                    Read more <TiArrowRight size={25} />
                                </Link>
                            </li>
                        ))}
                    </ul>
                    <Link
                        className={styles.viewAll}
                        to={'/blog'}
                        title="View all blogs"
                    >
                        View all blogs
                    </Link>
                </div>
            )
        }}
    />
)
